const headerWithLang = () => {
    let lang = window.localStorage.getItem('language');
    if(!lang)
    {
        lang = "zh-tw"
    }
    else
    {
        if (lang.startsWith("en"))
            lang = "en"
    }
    let header = 
    {
        'Content-Type': 'application/json',            
        'Accept-Language': lang.toLowerCase()
    }        
    return header;
}

export { headerWithLang };