import { authRequest,searchString } from './api/auth';

class ResourceDataService {
    export(page,type,stage,filter,search,tab,owner,activity,product){
        search = searchString(search)
        // return authRequest.get(`/resource/export/?page=${page}&type=${type}&stage=${stage}&filter=${filter}&search=${search}`);
        if(page=="Activity" && activity){
            let priority = activity.priority;
            let completed = activity.completed;
            let date_range = activity.date_range;
            let activity_type = activity.activity_type;
            let owner = activity.owner;
            let startDay = activity.startDay;
            let endDay = activity.endDay;
            let calendar = activity.calendar;
            return authRequest.get(`/activity/export?page=${page}&type=${type}&search=${search}&priority=${priority}&completed=${completed}&date_range=${date_range}&activity_type=${activity_type}&owner=${owner}&start_date=${startDay}&end_date=${endDay}&calendar=${calendar}`);
        }
        if(type=='db'){
            switch(page)
            {
                case "Contact":
                    return authRequest.get(`/contacts/export?page=${page}&type=${type}&stage=${stage}&filter=${filter}&search=${search}`);
                case "Organization":
                    return authRequest.get(`/organizations/export?page=${page}&type=${type}&stage=${stage}&filter=${filter}&search=${search}`);
                case "Case":
                    return authRequest.get(`/case/kanban/export?page=${page}&type=${type}&stage=${stage}&filter=${filter}&search=${search}&tab=${tab}&owner=${owner}`);
                case "Opportunity":
                    return authRequest.get(`/opportunity/export?page=${page}&type=${type}&stage=${stage}&filter=${filter}&search=${search}&tab=${tab}&owner=${owner}&product=${product}`);
                default:
                    return authRequest.get(`/resource/export/?page=${page}&type=${type}&stage=${stage}&filter=${filter}&search=${search}`);
            }
        }else{
            return authRequest.get(`/resource/export/?page=${page}&type=${type}&stage=${stage}&filter=${filter}&search=${search}`);
        }
    }
    import(csv,page,stage,track_id) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        let formData = new FormData();
        formData.append('file', csv);
        return authRequest.post(`/resource/import_csv/${csv.name}?import_page=${page}&stage=${stage}&track_id=${track_id}`, formData, config);
    }
    getnum(track_id){        
        return authRequest.get(`/resource/progress/?track_id=${track_id}`);
    }
    // import(csv) {
    //     return authRequest.get(`/resource/import_csv`);
    // }
}

export default new ResourceDataService();