import { authRequest } from './api/auth';

class TreeOptionsDataService {
    list() {
        return authRequest.get(`/tree_options/`);
    }

    create(data) {
        return authRequest.post(`/tree_options/`,data);
    }

    update(data) {
        return authRequest.put(`/tree_options/update/${data.id}`,data);
    }    

    delete(id) {
        return authRequest.delete(`/tree_options/delete/${id}`);
    }

    detail(id) {
        return authRequest.get(`/tree_options/${id}`);
    }
}
export default new TreeOptionsDataService();