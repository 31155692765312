import { authRequest } from './api/auth';

class O365Service {
    // Email
    addO365Account() {
        return authRequest.post(`/o365/`);
    }
    getO365Accounts() {
        return authRequest.get(`/o365/`);
    }
    deleteO365Accounts(id) {
        return authRequest.delete(`/o365/${id}/`);
    }
    sendEmail(formData) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return authRequest.post(`/email/`, formData, config);
    }
    getEmailAttachment(emailId) {
        return authRequest.get(`/email/${emailId}/attachments/`);
    }
    getContactList() {
        return authRequest.get(`/email/contacts/`);
    }
    getEmailSetting(id) {
        return authRequest.get(`/email/setting/?owner_id=${id}`);
    }
    editEmailSetting(data) {
        return authRequest.put(`/email/setting/${data.id}/`,data);
    }
    syncEmail() {
        return authRequest.post(`/email/sync/`);
    }

    // Calendar
    addO365CalendarAccount() {
        return authRequest.post(`/o365/calendar`);
    }
    getO365CalendarAccounts() {
        return authRequest.get(`/o365/calendar`);
    }
    deleteO365CalendarAccounts(id) {
        return authRequest.delete(`/o365/calendar/${id}`);
    }
    syncCalendar() {
        return authRequest.post(`/calendar/sync/`);
    }
    stopCalendar() {
        return authRequest.delete(`/calendar/sync/`);
    }
    getCalendarSetting(id) {
        return authRequest.get(`/calendar/setting/?owner_id=${id}`);
    }
    editCalendarSetting(data) {
        return authRequest.put(`/calendar/setting/${data.id}/`,data);
    }
    // OneDrive
    addO365OneDriveAccounts(data) {
        return authRequest.post(`/o365/onedrive`,data);
    }
    getO365OneDriveAccounts() {
        return authRequest.get(`/o365/onedrive`);
    }
    deleteO365OneDriveAccounts(id) {
        return authRequest.delete(`/o365/onedrive/${id}/`);
    }
}

export default new O365Service();