import { authRequest,searchString } from './api/auth';

class ContactDataService {
    getAll(page,search,pageitem,sortby,filter,upchat) {
        if(filter == undefined){
            filter = ""
        }
        if(upchat == undefined){
            upchat = ""
        }
        search = searchString(search)
        return authRequest.get(`/contacts/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&filter=${filter}&upchat=${upchat}`);
    }
    delete(id) {
        let ids = id.split(",");
        if (ids.length > 1){
            let contact_ids = {}
            contact_ids.ids = ids
            return authRequest.post("/contacts/bulk/delete/", contact_ids);
        }
        else
            return authRequest.delete(`/contacts/${id}/`);
    }    
    create(data,upchat) {
        delete data.image;  // image upload will be handled in image function
        if(upchat){
            return authRequest.post(`/contacts/?upchat=${upchat}`, data);
        }
        else{
            return authRequest.post("/contacts/?upchat=false", data);
        }
        // return authRequest.post("/contacts/", data);
    }
    edit(data) {
        delete data.image;  // image upload will be handled in image function
        delete data.owner.usersetting.photo;
        return authRequest.put(`/contacts/${data.id}/`, data);
    }
    image(id, image) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('image', image);
        return authRequest.patch(`/contacts/${id}/image/`, formData, config);
    }
    getContactListAll() {
        return authRequest.get(`/contactshortlist/`);
    }
    getContactsDetail(id) {
        return authRequest.get(`/contacts/${id}/detail/`);
    }
    getContactTimeline(id){
        return authRequest.get(`/contact/${id}/timeline/`);
    }
    editContactsDetail(id, data, isContact) {
        if(isContact)
            return authRequest.put(`/contacts/${id}/`, data);
    }
    createCompanyPrincipal(data) {
        return authRequest.post(`/companyprincipalcontacts/`, data);
    }
    editContactOrg(data) {
        return authRequest.put(`/contactsorg/${data.id}/`, data);
    }
    createContactOrg(data) {
        return authRequest.post(`/contactsorg/`, data);
    }
    createUpchatContacts(data) {
        return authRequest.post(`/contacts/upchatcontacts`, data);
    }
    getUpchatContacts(id) {
        return authRequest.get(`/contacts/upchatcontacts?id=${id}`);
    }
}

export default new ContactDataService();