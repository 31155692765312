import Vue from 'vue';
import VueRouter from 'vue-router';
import DealDashboard from '../views/DealDashboard.vue';
import CaseDashboard from '../views/CaseDashboard.vue';
import Activity from '../views/Activity.vue';
import Deals from '../views/Deals.vue';
import Deal from '../views/Deal.vue';
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Contacts from '../views/Contacts.vue';
import Contact from '../views/Contact.vue';
import Companies from '../views/Companies.vue';
import Account from '../views/Account.vue';
import Campaigns from '../views/Campaigns.vue';
import Campaign from '../views/Campaign.vue';
import Segments from '../views/Segments.vue';
import Segment from '../views/Segment.vue';
import Cases from '../views/Cases.vue';
import Case from '../views/Case.vue';
import KnowledgeArticles from '../views/KnowledgeArticles.vue';
import KnowledgeArticle from '../views/KnowledgeArticle.vue';
import Setting from "../views/Setting.vue";
import PermissionError from "../views/PermissionError.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Preference from "../views/Preference.vue";
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../services/api/auth';
import i18n from '/common/plugins/vue-i18n.js';
import UpChat from '../views/UpChat.vue';
import BTN from '../components/UpChat/BTN.vue';
import CRM from '../components/UpChat/CRM.vue';
// GPT-----------------------------------------------
import Knowledges from '../views-gpt/Knowledges.vue';
import Knowledge from '../views-gpt/Knowledge.vue';
import UsageAnalysis from '../views-gpt/UsageAnalysis.vue';
import AiBots from '../views-gpt/AiBots.vue';
import AiBot from '../views-gpt/AiBot.vue';
import UploadRecord from '../views-gpt/UploadRecord.vue';
import GptUserSetting from '../views-gpt/UserSetting.vue';
import ChatHistory from '../views-gpt/ChatHistory.vue';
import ManageUsers from '../views-gpt/ManageUsers.vue';
import MeetingRecaps from '../views-gpt/MeetingRecaps.vue';
import MeetingRecap from '../views-gpt/MeetingRecap.vue';
import AINotebooks from '../views-gpt/AINotebooks.vue';
import AINotebook from '../views-gpt/AINotebook.vue';
import UpMeeting from '../views-gpt/UpMeeting.vue';


Vue.use(VueRouter);

const PUBLIC_PATHS = ['/login','/resetpassword','/upmeeting'];
const UPGPT_PATHS = ['/upgpt/MeetingRecap','/upgpt/UserSetting','/upgpt/PermissionError','/upgpt/PageNotFound'];

let lang = window.localStorage.getItem('language');  
if(!lang)
  {
    lang = "zh_TW"
  }   
i18n.locale = lang

let isUpGPTUser = window.localStorage.getItem('isUpGPTUser');
let isAdmin = window.localStorage.getItem('is_staff');

const routes = [
  {
    path: "/",
    name: "UpDay",
    component: DealDashboard,
    normal: "icon-home-border",
    active: "icon-home",
  },
  {
    path: "/DealDashboard",
    name: i18n.t('Bower_Title.Oppty_Dashboard'),
    component: DealDashboard,
    normal: "icon-dashboard-border",
    active: "icon-dashboard",
  },
  {
    path: "/CaseDashboard",
    name: i18n.t('Bower_Title.Case_Dashboard'),
    component: CaseDashboard,
    normal: "icon-dashboard-border",
    active: "icon-dashboard",
  },
  {
    path: "/Activity",
    name: i18n.t('Bower_Title.Activity'),
    component: Activity,
    normal: "mdi-alien-outline",
    active: "mdi-alien",
  },
  {
    path: "/Deals",
    name: i18n.t('Bower_Title.Oppty_List'),
    component: Deals,
    normal: "icon-chatmoney-border",
    active: "icon-chatmoney",
  },
  {
    path: "/Deal/:id",
    name: i18n.t('Bower_Title.Oppty'),
    component: Deal,
    normal: "icon-chatmoney-border",
    active: "icon-chatmoney",
  },
  {
    path: "/Login",
    name: i18n.t('Bower_Title.Login'),
    component: Login,	
  },
  {
    path: "/resetpassword",
    name: i18n.t('Bower_Title.ResetPasswordogin'),
    component: ResetPassword,	
  },
  {
    path: "/Contacts",
    name: i18n.t('Bower_Title.Contacts_List'),
    component: Contacts,
    normal: "icon-contact-border",
    active: "icon-contact",
  },
  {
    path: "/Contacts/:id",
    name: i18n.t('Bower_Title.Contact'),
    component: Contact,
    normal: "icon-contact-border",
    active: "icon-contact",
  },
  {
    path: "/Companies",
    name: i18n.t('Bower_Title.Company_List'),
    component: Companies,
    normal: "icon-companies-w",
    active: "icon-companies-b",
  },
  {
    path: "/Account/:id",
    name: i18n.t('Bower_Title.Company'),
    component: Account,
    normal: "icon-companies-w",
    active: "icon-companies-b",
  },
  {
    path: "/Campaigns",
    name: i18n.t('Bower_Title.Campaign_List'),
    component: Campaigns,
    normal: "mdi-bullhorn-outline",
    active: "mdi-bullhorn",
  },
  {
    path: "/Campaign/:id",
    name: i18n.t('Bower_Title.Campaign'),
    component: Campaign,
    normal: "mdi-bullhorn-outline",
    active: "mdi-bullhorn",
  },
  {
    path: "/Segments",
    name: i18n.t('Bower_Title.Segment_List'),
    component: Segments,
    normal: "mdi-folder-table-outline",
    active: "mdi-folder-table-outline",
  },
  {
    path: "/Segment/:id",
    name: i18n.t('Bower_Title.Segment'),
    component: Segment,
    normal: "mdi-folder-table-outline",
    active: "mdi-folder-table-outline",
  },
  {
    path: "/Cases",
    name: i18n.t('Bower_Title.Case_List'),
    component: Cases,
    normal: "mdi-wrench-outline",
    active: "mdi-wrench",
  },
  {
    path: "/Case/:id",
    name: i18n.t('Bower_Title.Case'),
    component: Case,
    normal: "mdi-folder-table-outline",
    active: "mdi-folder-table-outline",
  },
  {
    path: "/KnowledgeArticles",
    name: i18n.t('Bower_Title.KnowledgeArticle_List'),
    component: KnowledgeArticles,
    normal: "mdi-folder-table-outline",
    active: "mdi-folder-table-outline",
  },
  {
    path: "/KnowledgeArticle/:id",
    name: i18n.t('Bower_Title.KnowledgeArticle'),
    component: KnowledgeArticle,
    // normal: "mdi-folder-table-outline",
    // active: "mdi-folder-table-outline",
  },
  {
    path: "/Setting",
    name: i18n.t('Bower_Title.Setting'),
    component: Setting,
    normal: "icon-setting-w",
    active: "icon-setting-b",
  },
  {
    path: "/PermissionError",
    name: i18n.t('Bower_Title.PermissionError'),
    component: PermissionError,
    normal: "icon-setting-w",
    active: "icon-setting-b",
  },  
  {
    path: "/Preferences",
    name: "Preferences",
    component: Preference,
    normal: "icon-home-border",
    active: "icon-home",
  },
  {
    path: "/UpChat",
    name: i18n.t('Bower_Title.UPCHAT'),
    component: UpChat,
    normal: "icon-home-border",
    active: "icon-home",
    meta:{
      upchat:true,
    }
  },
  {
    path: "/BTN",
    name: "BTN",
    component: BTN,
    normal: "icon-home-border",
    active: "icon-home",
    meta:{
      showMenu:false
    }
  },
  {
    path: "/CRM",
    name: "CRM",
    component: CRM,
    normal: "icon-home-border",
    active: "icon-home",
    meta:{
      showMenu:false
    }
  },
  {
    path: "/PageNotFound",
    name: i18n.t('Bower_Title.PageNotFoundError'),
    component: PageNotFound,
    normal: "icon-setting-w",
    active: "icon-setting-b",
  },
  // GPT----------------------------
  {
    path: "/upgpt/",
    name: "UpGPT",
    component: Knowledges,
    normal: 'icon-dashboard-border',
    active: 'icon-dashboard',
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: "/upgpt/Dashboard",
    name: i18n.t('GPT_MENU.DASHBOARD'),
    normal: 'icon-dashboard-border',
    active: 'icon-dashboard',
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
      path: '/upgpt/Knowledges',
      name: i18n.t('GPT_KNOWLEDGE.TITLE'),
      component: Knowledges,
      normal: 'mdi-book-open-variant',
      active: 'mdi-book-open-page-variant',
      meta:{
        upgpt:isUpGPTUser,
      }
  },
  {
      path: '/upgpt/Chatbot',
      name: i18n.t('GPT_AIBOTS.TITLE'),
      component: AiBots,
      normal: 'fas fa-robot',
      active: 'fas fa-robot',
      meta:{
        upgpt:isUpGPTUser,
      }
  },
  {
      path: '/upgpt/ChatHistory',
      name: i18n.t('GPT_MENU.CHAT_HISTORY'),
      component: ChatHistory,
      normal: 'far fa-comment',
      active: 'fas fa-comments',
      meta:{
        upgpt:isUpGPTUser,
      }
  },
  {
      path: '/upgpt/UserManagement',
      name: i18n.t('GPT_MENU.USER_MANAGEMENT'),
      component: ManageUsers,
      normal: 'fas fa-user-friends',
      active: 'fas fa-users-cog',
      meta:{
        upgpt:isUpGPTUser,
      }
  },
  {
      path: '/upgpt/UsageAnalysis',
      name: i18n.t('GPT_MENU.USAGE_ANALYSIS'),
      component: UsageAnalysis,
      normal: 'mdi-chart-pie',
      active: 'fas fa-chart-pie',
      meta:{
        upgpt:isUpGPTUser,
      }
  },
  {
    path: '/upgpt/Knowledges/:id',
    name: i18n.t('GPT_MENU.KNOWLEDGE'),
    component: Knowledge,
    normal: 'mdi-book-open-variant',
    active: 'mdi-book-open-page-variant',
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: '/upgpt/Chatbot/:id',
    name: i18n.t('GPT_MENU.CHATBOT'),
    component: AiBot,
    normal: 'mdi-book-open-variant',
    active: 'mdi-book-open-page-variant',
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: '/upgpt/Uploadrecord/',
    name: i18n.t('GPT_MENU.UPLOADSTATUS'),
    component: UploadRecord,
    normal: 'mdi-folder-upload',
    active: 'mdi-folder-upload',
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: '/upgpt/UserSetting/',
    name: i18n.t('SETTING.PERSONALSETTING'),
    component: GptUserSetting,
    normal: "mdi-account",
    active: "mdi-account",
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: '/upgpt/MeetingRecap/',
    name: i18n.t('GPT_MEETINGRECAPS.TITLE'),
    component: MeetingRecaps,
    normal: "mdi-account",
    active: "mdi-account",
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: '/upgpt/MeetingRecap/:id',
    name: i18n.t('GPT_MEETINGRECAP.TITLE'),
    component: MeetingRecap,
    normal: "mdi-account",
    active: "mdi-account",
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: '/upgpt/AINotebook/',
    name: i18n.t('GPT_MENU.AINOTEBOOK'),
    component: AINotebooks,
    normal: "mdi-account",
    active: "mdi-account",
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: '/upgpt/AINotebook/:id',
    name: i18n.t('GPT_MENU.AINOTEBOOK')+" ",
    component: AINotebook,
    normal: "mdi-account",
    active: "mdi-account",
    meta:{
      upgpt:isUpGPTUser,
    }
  },
  {
    path: "/upgpt/PageNotFound",
    name: i18n.t('Bower_Title.PageNotFoundError')+" ",
    component: PageNotFound,
    normal: "icon-setting-w",
    active: "icon-setting-b",
  },
  {
    path: "/upgpt/PermissionError",
    name: i18n.t('Bower_Title.PermissionError')+" ",
    component: PermissionError,
    normal: "icon-setting-w",
    active: "icon-setting-b",
  }, 
  // UPMEETING----------------------------
  {
    path: "/upmeeting",
    name: i18n.t('UPMEETING.LOGIN')+" ",
    component: UpMeeting,
    normal: "icon-setting-w",
    active: "icon-setting-b",
  }, 
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

const unAuthenticatedAndPrivatePage = (path) => (!PUBLIC_PATHS.includes(path)
  && !(ACCESS_TOKEN in window.localStorage)
  && !(REFRESH_TOKEN in window.localStorage));

router.beforeEach((to, from, next) => {
  if (unAuthenticatedAndPrivatePage(to.path)) {
    let nowPath = router.currentRoute.fullPath;
    let tenantParam = 'login?tenant=';
    let toPath = `/login?next=${to.path}`;
    let query_params = nowPath.slice(nowPath.indexOf(tenantParam) + tenantParam.length);
    let tenant = (query_params.includes('&')) ? query_params.split('&')[0] : query_params;    
    if(nowPath.includes(tenantParam)) {
      toPath = `/login?tenant=${tenant}&next=${to.path}`;
    }
    next(toPath);
  } else {
    // if url has upchat=Y, redirect to upchat sso link if available, else go to dashboard
    var fp = to.fullPath;
    if (fp.includes('upchat=Y')) {
      let user = window.localStorage.user;
      if (user != null && user != undefined) {
        let userJson = JSON.parse(window.localStorage.user);
        if (userJson.upchat_sso_link != '')
          window.location.href = userJson.upchat_sso_link; 
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

// router.beforeEach((to, from, next) => {
//   document.title = to.name;
//   next();
// });

router.beforeEach((to, from, next) => {
    if(!to.path.includes('upmeeting')){
      document.title = to.name;
      localStorage.setItem('title', to.name);
    }
    if(to.params.id){
      sessionStorage.setItem('page_id', to.params.id);
    }
    if(to.path.includes('/upgpt')){
      if(isUpGPTUser == "false"){
        next('/');
      }
      else if(isAdmin!='false'){
        next();
      }else{
        if(UPGPT_PATHS.some(el => to.path.includes(el))){
          next();
        }else{
          if(to.path == '/upgpt' || to.path == '/upgpt/'){
            next('/upgpt/MeetingRecap');
          }else{
            next('/upgpt/PermissionError');
          }
        }
      }
    }else{
      next();
    }
});

export default router
