import { authRequest } from './api/auth';

class NoteDataService {
    createNote(data){
        return authRequest.post(`/notes/`, data);
    }
    editNote(data){
        return authRequest.put(`/notes/${data.id}/`, data);
    }
    deleteNote(id){
        return authRequest.delete(`/notes/${id}/`);
    }
}
export default new NoteDataService();