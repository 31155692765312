import { publicRequest } from './api/auth';

class InvitationDataService {
    getAll() {
        return publicRequest.get("/api/invitations/");
    }
    create(data) {
        return publicRequest.post("/api/invitations/", data);
    }
    verifyCaptcha(data) {
        return publicRequest.post(`/api/verify-captcha/`, data);
    }
}

export default new InvitationDataService();