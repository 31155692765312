import { authRequest } from './api/auth';

class StageDataService {
    stageList(name) {
        return authRequest.get(`/stagetype/?page=${name}`);
    }
    create(data) {
        return authRequest.post('/stagetype/', data);
    }
    delete(id) {
        return authRequest.delete(`/stagetype/${id}/`);
    }
    deleteStep(id) {
        return authRequest.delete(`/stagedef/${id}/`);
    }
    update(data) {
        return authRequest.put(`/stagetype/${data.id}/`, data);
    }
}
export default new StageDataService();