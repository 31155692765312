import { authRequest } from './api/auth';

class PersonalSettingService {
    getCurrentUserDetail() {
        return authRequest.get(`/personalsetting/`);
    }    
    updateDetail(id,data) {
        return authRequest.put(`/personalsetting/${id}/`,data);
    }
    image(id, photo) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('photo', photo);
        return authRequest.patch(`/personalsetting/${id}/image/`, formData, config);
    }
    updateStatus(id,data) {
        return authRequest.post(`/personalsetting/${id}/status/`,data);
    }
    getUserDetail() {
        return authRequest.get(`/manageusers/`);
    }
    createUserDetail(data,lang) {
        return authRequest.post(`/manageusers/?lang=${lang}`,data);
    }
    updateUserDetail(data) {
        return authRequest.put(`/manageusers/${data.id}/`,data);
    }
    deldteUserDetail(id) {
        return authRequest.delete(`/manageusers/${id}/delete/`);
    } 
    updateUserTimelineTab(id,data) {
        return authRequest.put(`/personalsetting/${id}/timelinetab/`,data);
    }
    getTableWidth(id) {
        return authRequest.get(`/personalsetting/${id}/tableresize/`);
    }
    updateTableWidth(id,data) {
        return authRequest.post(`/personalsetting/${id}/tableresize/`,data);
    }
    // updateContactCustomFieldDetail(id,data) {
    //     return authRequest.put(`/customfield/contact/${id}`,data);
    // }
    // updateOrgCustomFieldDetail(id,data) {
    //     return authRequest.put(`/customfield/org/${id}`,data);
    // }
    // updateSettingCustomField(data){
    //     return authRequest.put(`/customfield/${data.id}`,data);
    // }
}
export default new PersonalSettingService();