import { authRequest } from './api/auth';

class DocumentDataService {
    create(data,document){
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('class_name', data.class_name);
        if(String(data.description).length>0 && String(data.description)!= 'null'){
            formData.append('description', data.description);
        }else{
            formData.append('description', '');
        }
        formData.append('linked_id', data.linked_id);
        delete formData.document;
        formData.append('document', document);
        return authRequest.post(`/document/`, formData,config);
    }
    list(class_name,id){
        return authRequest.get(`/document/?class=${class_name}&linked_id=${id}`);
    }
    edit(data,document){
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('class_name', data.class_name);
        if(String(data.description).length>0 && String(data.description)!= 'null'){
            formData.append('description', data.description);
        }else{
            formData.append('description', '');
        }
        formData.append('linked_id', data.linked_id);
        formData.append('id', data.id);
        delete formData.document;
        formData.append('document', document);
        return authRequest.patch(`/document/${data.id}/update/`, formData,config);
    }
    delete(id){
        return authRequest.delete(`/document/${id}/delete/`);
    }
    bulk_delete(ids){
        return authRequest.post(`/document/delete/`,ids);
    }
}
export default new DocumentDataService();