import { authRequest } from './api/auth';

class FollowDataService {
    getSingleFollow(data) {
        return authRequest.get(`/follow/${data.id}/`);
    }
    getAllFollow() {
        return authRequest.get(`/follow/`);
    }
    createFollow(data) {
        return authRequest.post(`/follow/`,data);
    }
    updateFollow(data) {
        return authRequest.put(`/follow/${data.id}`,data);
    }
    deleteFollow(id) {
        return authRequest.delete(`/follow/${id}/`);
    }
    bulkCreateDeleteFollow(data) {
        return authRequest.post(`/follow/bulk/`,data);
    }
    
}
export default new FollowDataService();