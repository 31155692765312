import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyBW-86BI4yXSLdJBgeQ42K0vW9VLDtNRR4",
    authDomain: "cm2-crmapp-prod.firebaseapp.com",
    projectId: "cm2-crmapp-prod",
    storageBucket: "cm2-crmapp-prod.appspot.com",
    messagingSenderId: "32944569494",
    appId: "1:32944569494:web:4ebd73455b2409571996b7",
    measurementId: "G-7P1THQT4VT"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export default messaging;