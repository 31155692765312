import { authRequest } from './api/auth';

class AutoNumberService {
    list() {
        return authRequest.get('/auto_number/');
    }
    edit(data) {
        return authRequest.put(`/auto_number/${data.id}`, data);
    }    
}
export default new AutoNumberService();