import { authRequest } from './api/auth';

class UpGPTDataService {
    getSettingData() {
        return authRequest.get(`/chatgpt/setbasicsetting/`);
    }

    setSettingData(data) {
        return authRequest.post(`/chatgpt/setbasicsetting/`,data);
    }

    reloadChroma_db() {
        return authRequest.post(`/chatgpt/reloaddb_from_admin/`);
    }
    
    resetAllKnowledge() {
        return authRequest.post(`/chatgpt/resetallknowledge/`);
    }

    uploadQAfile(file) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('file', file);
        return authRequest.post(`/chatgpt/uploadqafile/`,formData,config);
    }

    uploadFolder(file) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('file', file);
        return authRequest.post(`/chatgpt/uploadfolder/`,formData,config);
    }

    uploadFile(file) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('file', file);
        return authRequest.post(`/chatgpt/uploadfile/`,formData,config);
    }

    getKnowledgeInfo() {
        return authRequest.post(`/chatgpt/getknowledgeinfo/`);
    }

    downloadtxt(filename) {
        return authRequest.post(`/chatgpt/downloadtxt/`,filename);
    }

    getPromptSettingData() {
        return authRequest.get(`/chatgpt/promptsetting`);
    }

    setPromptSettingData(data) {
        return authRequest.post(`/chatgpt/promptsetting`,data);
    }

    getWebsitesSettingData() {
        return authRequest.get(`/chatgpt/setwebsitessetting/`);
    }

    setWebsitesSettingData(data) {
        return authRequest.post(`/chatgpt/setwebsitessetting/`,data);
    }

    updateWebsitesSettingData(id,data) {
        return authRequest.put(`/chatgpt/setwebsitessetting/${id}`,data);
    } 

    deleteWebsitesSettingData(id) {
        return authRequest.delete(`/chatgpt/setwebsitessetting/${id}/`);
    }
    
    downloadwebzip() {
        return authRequest.post(`/chatgpt/downloadwebzip/`);
    }

    websiteanalysis() {
        return authRequest.post(`/chatgpt/websiteanalysis`);
    } 
    campaign_message_generate(data) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return authRequest.post(`/chatgpt/openai/generate_campaign_content/`,data,config);
    }
    get_campaign_message(data) {
        return authRequest.post(`/chatgpt/openai/get_campaign_content/`,data,);
    }
    save_or_undo_campaign_message(data) {
        return authRequest.post(`/chatgpt/openai/save_and_undo_campaign_content/`,data,);
    }
}
export default new UpGPTDataService();