import { authRequest } from './api/auth';

class BulkEditDataService {
    editContact(data){
        return authRequest.post(`/bulkedit/contact/`, data);
    }
    editOrg(data){
        return authRequest.post(`/bulkedit/org/`, data);
    }
    editCase(data){
        return authRequest.post(`/bulkedit/case/`, data);
    }
    editOppty(data){
        return authRequest.post(`/bulkedit/oppty/`, data);
    }
}
export default new BulkEditDataService();