import { authRequest } from './api/auth';

class CustomViewDataService {
    getFieldList(class_type, id) {
        return authRequest.get(`/customView/fieldList/?class_type=${class_type}&stage_type_id=${id}`);
    }
    getCustomView(class_name) {
		return authRequest.get(`/customView/?class_name=${class_name}`);
    }
    addCustomView(data) {
        return authRequest.post('/customView/', data);
    }
    updateCustomView(data) {
        return authRequest.put(`/customView/${data.id}/`, data);
    }
}
export default new CustomViewDataService();