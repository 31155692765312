import { authRequest } from './api/auth';

class CustomFieldDataService {
    getCustomFieldDetail(data) {
        return authRequest.get(`/customfield/?class=${data}`);
    }
    updateOpptyCustomFieldDetail(id,data) {
        return authRequest.put(`/customfield/oppty/${id}/`,data);
    }
    updateContactCustomFieldDetail(id,data) {
        return authRequest.put(`/customfield/contact/${id}/`,data);
    }
    updateCaseCustomFieldDetail(id,data) {
        return authRequest.put(`/customfield/case/${id}/`,data);
    }
    updateOrgCustomFieldDetail(id,data) {
        return authRequest.put(`/customfield/org/${id}/`,data);
    }
    updateSettingCustomField(data){
        return authRequest.put(`/customfield/${data.id}/`,data);
    }
}
export default new CustomFieldDataService();