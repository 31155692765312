import { authRequest } from './api/auth';

class UploadStatusDataService {
    list() {
        return authRequest.get(`/uploadstatus/`);
    }
    create(data) {
        return authRequest.post(`/uploadstatus/`,data);
    }
    bulk_delete(data) {
        return authRequest.post(`/uploadstatus/bulkdelete/`,data);
    }
    analysis_check() {
        return authRequest.get(`/uploadstatus/?check=true`);
    }
}

export default new UploadStatusDataService();