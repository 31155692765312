import { authRequest,searchString } from './api/auth';

class SegmentDataService {
    getAll(page,search,pageitem,sortby) {
        search = searchString(search)
        return authRequest.get(`/segment/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}`);
    }
    getDetail(id) {
        return authRequest.get(`/segment/${id}/`);
    }
    editDetail(id,data) {
        return authRequest.put(`/segment/${id}/`,data);
    }
    // getContactList(id) {
    //     return authRequest.get(`/segment/${id}/addcontact/`);
    // }
    getContactList(page,search,pageitem,sortby) {
        search = searchString(search)
        return authRequest.get(`/contacts/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}`);
    }
    addSegment(data) {
        return authRequest.post(`/segment/`,data);
    }
    editSegment(data) {
        return authRequest.put(`/segment/${data.id}/`,data);
    }
    // delete(id) {
    //     return authRequest.delete(`/segment/${id}/`);
    // }    
    delete(id) {
        let ids = id.split(",");
        if (ids.length > 1){
            let segment_ids = {}
            segment_ids.ids = ids
            return authRequest.post("/segment/bulk/delete/", segment_ids);
        }
        else
            return authRequest.delete(`/segment/${id}/`);
    } 
    shortList() {
        return authRequest.get("/segment/shortlist/");
    }
    contactsList(id) {
        return authRequest.get(`/segment/contacts/${id}/`);
    }
    // create(data) {
    //     return authRequest.post("/emailtemplates/", data);
    // }
    // edit(data) {
    //     return authRequest.put(`/emailtemplates/${data.id}/`, data);
    // }
    // dashboardEmailList(data) {
    //     return authRequest.get(`/email/dashboard/?mode=${data.mode}&startDate=${data.startDate}&endDate=${data.endDate}`, data);
    // }
}

export default new SegmentDataService();
