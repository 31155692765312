import { authRequest,searchString } from './api/auth';

class ActivityDataService {
    getActivity(page, search, pageitem, sortby, priority, completed, date_range, activity_type, owner,startDate,endDate,calendar,target_id,from_page) {
        search = searchString(search)
        return authRequest.get(`/activity/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&priority=${priority}&completed=${completed}&date_range=${date_range}&activity_type=${activity_type}&owner=${owner}&start_date=${startDate}&end_date=${endDate}&calendar=${calendar}&target_id=${target_id}&from_page=${from_page}`);
    }
	// getActivityTest(page, pageitem, owner) {
    //     return authRequest.get(`/activity/?page=${page}&search=&pageitem=${pageitem}&sortby=&priority=&completed=&date_range=&activity_type=&owner=${owner}`);
    // }
    getActivityDetail(id) {
        return authRequest.get(`activity/${id}/`);
    }
    addActivity(data) {
        return authRequest.post(`activity/`, data);
    }
    updateActivity(data) {
        return authRequest.put(`activity/${data.id}/`, data);
    }
    updateActivityCompleted(data) {
        return authRequest.put(`activity/${data.id}/completed/`, data);
    }
    updateActivityDate(data) {
        return authRequest.put(`activity/${data.id}/date/`, data);
    }
    deleteActivity(id) {
        let ids = id.split(",");
        if (ids.length > 1){
            let activity_ids = {};
            activity_ids.ids = ids;
            return authRequest.post("/activity/bulk/delete/", activity_ids);
        }
        else{
            return authRequest.delete(`activity/${id}/`);
        }
    }  
	getActivityType() {
        return authRequest.get('/activity/activitytypes');
    }
    editActivityType(data) {
        return authRequest.put(`/activity/activitytypes/${data.id}/`,data);
    }
    deleteActivityType(id) {
        return authRequest.delete(`/activity/activitytypes/${id}/`);
    }
    addActivityType(data) {
        return authRequest.post(`/activity/activitytypes`,data);
    }
    getActivityTimeline(target_id,from_page){
        return authRequest.get(`/activity/timeline?target_id=${target_id}&from_page=${from_page}`)
    }
}

export default new ActivityDataService();