import { authRequest } from './api/auth';

class dashboardDataService{
    getDealflow(data){
        return authRequest.get(`/dealflow/?stage_type=${data.process}&owner=${data.people}`);
    }
    getRevenueForecast(data, range, startDay, endDay){
        if(!startDay && !endDay)
            return authRequest.get(`/revenue-forecast/?owner=${data.people}&stage_type=${data.process}&day_range=${range}`);
        else
            return authRequest.get(`/revenue-forecast/?owner=${data.people}&stage_type=${data.process}&day_range=${range}&start_date=${startDay}&end_date=${endDay}`);
    }
    getSalesRanking(data){
        return authRequest.get(`/salesrankinglist/?mode=${data.mode}&startDate=${data.startDate}&endDate=${data.endDate}`, data);
    }
    getCaseDashboardData(agent, process, range, startDay, endDay){
        if(!startDay && !endDay)
            return authRequest.get(`/case/dashboard/?owner=${agent}&stage_type=${process}&day_range=${range}`);
        else
            return authRequest.get(`/case/dashboard/?owner=${agent}&stage_type=${process}&day_range=${range}&start_date=${startDay}&end_date=${endDay}`);
    }
    getCaseTableData(agent, process, key, level,page,sort){
        if (level == undefined)
            return authRequest.get(`/case/dashboard/?owner=${agent}&stage_type=${process}&key=${key}&page=${page}&sortby=${sort}`);
        else
            return authRequest.get(`/case/dashboard/?owner=${agent}&stage_type=${process}&key=${key}&priority=${level}&page=${page}&sortby=${sort}`);
    }
}

export default new dashboardDataService();