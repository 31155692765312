import { publicRequest } from './api/auth';

class LoginValidationService {
    hasLoginValidation(tenant) {
        return publicRequest.get(`/api/login/validation/?tenant=${tenant}&code=LOGIN_VALIDATION`);
    }
    sendLoginValidationCode(tenant, username) {
        return publicRequest.post(`/api/login/validation/?tenant=${tenant}&code=LOGIN_VALIDATION&username=${username}`);
    }
}

export default new LoginValidationService();
