import { authRequest } from './api/auth';

class UpGPTDataintegrationService {
    cloud_files_list(data) {
        return authRequest.post(`/data_integration/getm365storage/`,data);
    }
    get_folder_files_list(data) {
        return authRequest.post(`/data_integration/getm365storage/folder/`,data);
    }
    upload_files(data) {
        return authRequest.post(`/data_integration/uploadintegrationdatas/`,data);
    }
}

export default new UpGPTDataintegrationService();