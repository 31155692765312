import { authRequest } from './api/auth';

class CustomFilterDataService {
    create(data) {
        return authRequest.post('customFilter/', data);
    }
    list() {
        return authRequest.get('customFilter/');
    }
    update(data) {
        return authRequest.put(`customFilter/${data.id}/`, data);
    }
    delete(id) {
        return authRequest.delete(`customFilter/${id}/`);
    }
    userDefault(data) {
        return authRequest.put(`customFilter/${data.id}/userDefault/`, data);
    }
}

export default new CustomFilterDataService();