import { authRequest,searchString } from './api/auth';

class CaseDataService {
    list() {
        return authRequest.get('/case/');
    }
    kanabn(tab, stage_type_id, owner_id,page,search,pageitem,sortby,filter) {
        if(filter == undefined){
            filter = ""
        }
        search = searchString(search)
        return authRequest.get(`/case/kanban/?tab=${tab}&stage_type=${stage_type_id}&owner=${owner_id}&page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&filter=${filter}`)
    }
    kanabn_stage(tab, stage_type_id, owner_id,page,search,pageitem,sortby,current_stage_id,roll_time,filter) {
        if(filter == undefined){
            filter = ""
        }
        search = searchString(search)
        return authRequest.get(`/case/kanban/?tab=${tab}&stage_type=${stage_type_id}&owner=${owner_id}&page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&current_stage_id=${current_stage_id}&roll_time=${roll_time}&filter=${filter}`)
    }
    detailcard(data) {
        return authRequest.get(`/case/detailcard/?contact=${data.contact}&org=${data.org}&oppty=${data.oppty}`)
    }
    create(data) {
        return authRequest.post('/case/', data);
    }
    detail(id) {
        return authRequest.get(`/case/${id}/`)
    }
    editdetail(data) {
        switch(data.priority_number){
            case "1":
                data.priority = "VERY_LOW";
                break;
            case "2":
                data.priority = "LOW";
                break;
            case "3":
                data.priority = "MEDIUM";
                break;
            case "4":
                data.priority = "HIGH";
                break;
            case "5":
                data.priority = "VERY_HIGH";
                break;
            default:
                break;
        }
        return authRequest.put(`/case/${data.id}/`, data)
    }
    timeline(id) {
        return authRequest.get(`/case/${id}/timeline/`)
    }
    updateparentcase(data) {
        return authRequest.put(`/case/${data.id}/parentcase/`, data)
    }
    updatestage(id, data) {
        return authRequest.put(`/case/${id}/currentstage/`, data)
    }
    addrelatedcontacts(id, data) {
        return authRequest.post(`/case/${id}/relatedContacts/`, data)
    }
    getrelatedcontactsList(id) {
        return authRequest.get(`/case/${id}/relatedContacts/`)
    }
    deleterelatedcontact(case_id, contact_id) {
        return authRequest.delete(`/case/${case_id}/relatedContacts/${contact_id}`)
    }
    delete(id) {
        return authRequest.delete(`/case/${id}/`)
    }
    getrelatedarticles(id, page, search) {
        if (search != undefined) {
            search = searchString(search)
            return authRequest.get(`/case/${id}/relatedarticles/list/?page=${page}&search=${search}`)
        } else {
            return authRequest.get(`/case/${id}/relatedarticles/list/?page=${page}`)
        }
    }
    addrelatedarticles(id, data) {
        return authRequest.post(`/case/${id}/relatedarticles/`, data)
    }
    removerelatedarticles(id, data) {
        return authRequest.delete(`/case/${id}/relatedarticles/${data.id}/`, data)
    }
    // case group
    getcasegrouplist() {
        return authRequest.get('/case/group/');
    }
    casegroupcreate(data) {
        return authRequest.post('/case/group/', data);
    }
    casegroupupdate(data) {
        return authRequest.put(`/case/group/${data.id}`, data);
    }
    casegroupsequence_number(data) {
        return authRequest.post('/case/group/sequence_number/',data);
    }
    casegroupcdelete(id) {
        return authRequest.delete(`/case/group/${id}`);
    }
    // case type
    getcasetypelist() {
        return authRequest.get('/case/type/');
    }
    casetypecreate(data) {
        return authRequest.post('/case/type/', data);
    }
    casetypeupdate(data) {
        return authRequest.put(`/case/type/${data.id}`, data);
    }
    casetypesequence_number(data) {
        return authRequest.post('/case/type/sequence_number/',data);
    }    
    casetypedelete(id) {
        return authRequest.delete(`/case/type/${id}`);
    }
    // case category
    getcasecategorylist() {
        return authRequest.get('/case/category/');
    }
    casecategorycreate(data) {
        return authRequest.post('/case/category/', data);
    }
    casecategoryupdate(data) {
        return authRequest.put(`/case/category/${data.id}`, data);
    }
    casecategorysequence_number(data) {
        return authRequest.post('/case/category/sequence_number/',data);
    }
    casecategorycdelete(id) {
        return authRequest.delete(`/case/category/${id}`);
    }
    // add case product
    addcaseproduct(data){
        return authRequest.post(`/case/${data.case}/caseproduct/`,data);
    }
    deletecaseproduct(data){
        return authRequest.post(`/case/${data.case}/caseproduct/delete/`,data);
    }

    // case kanban agent list
    getAgentList(){
        return authRequest.get('/case/agentList/');
    }

    bulkdelete(id){
        let ids = id.split(",");
        let case_ids = {}
        case_ids.ids = ids
        return authRequest.post('/case/bulk/delete/', case_ids);
    }
}
export default new CaseDataService();