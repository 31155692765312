import { authRequest } from './api/auth';
import { tokenRequest } from './api/auth';

class AccountDataService {
    changePassword(data) {
        return authRequest.put('/account/changepassword/', data);
    }
    forgetPassword(data) {
        let tenant = data.tenant;
        return tokenRequest.post(`${tenant}/api/account/passwordreset/`, data);
    }
    confirmPassword(data) {
        let tenant = data.tenant;
        let obj = { 'token': data.token, 'password': data.password };
        return tokenRequest.post(`${tenant}/api/account/passwordreset/confirm/`, obj);
    }
    saveNotificationToken(token) {
        return authRequest.post(`/firebase/fcm/token/`, token);
    }
}
export default new AccountDataService();