import { authRequest } from './api/auth';

class PermissionService {
    getUserPermissions() {
        return authRequest.get(`/permissions/`);
    }
    getGroups() {
        return authRequest.get(`/permissions/groups/`);
    }    
    addGroup(data) {
        return authRequest.post(`/permissions/groups/`, data);
    }    
    editGroup(data) {
        return authRequest.put(`/permissions/groups/${data.id}/`, data);
    }    
    deleteGroup(id) {
        return authRequest.delete(`/permissions/groups/${id}/`);
    }    
}
export default new PermissionService();