import { authRequest } from "./api/auth";

class NotificationDataService {
  noticelist() {
    return authRequest.get("/notification/");
  }
  activatednoticelist() {
    return authRequest.get("/notification/activated/");
  }
  createnotice(data) {
    return authRequest.post("/notification/", data);
  }
  editnotice(data) {
    return authRequest.put(`/notification/${data.id}/`, data);
  }
  deletenotice(id) {
    return authRequest.delete(`/notification/${id}/`);
  }
  getTimer() {
    return authRequest.get(`/preference/?code=NOTIFICATION_TIMER`);
  }
  createTimer(data) {
    return authRequest.post(`/preference/`, data);
  }
  editTimer(data) {
    return authRequest.put(`/preference/?code=NOTIFICATION_TIMER`, data);
  }
}
export default new NotificationDataService();
