import { authRequest } from './api/auth';

class EmailTemplateDataService {
    getAll() {
        return authRequest.get("/emailtemplates/");
    }
    getAllForCompose(type, id) {
        return authRequest.get(`/emailtemplatescompose/?type=${type}&id=${id}`);
    }
    delete(id) {
        return authRequest.delete(`/emailtemplates/${id}/`);
    }    
    create(data) {
        return authRequest.post("/emailtemplates/", data);
    }
    edit(data) {
        return authRequest.put(`/emailtemplates/${data.id}/`, data);
    }
    dashboardEmailList(data) {
        return authRequest.get(`/email/dashboard/?mode=${data.mode}&startDate=${data.startDate}&endDate=${data.endDate}&people=${data.people}`, data);
    }
    transformEmail(type, id, data) {
        return authRequest.post(`/emailtemplatesbody/?type=${type}&id=${id}`, data);
    }
}

export default new EmailTemplateDataService();
