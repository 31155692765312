import { authRequest } from './api/auth';

class TwilioService {
    sendSMS(data) {
        return authRequest.post('sms/', data);
    }
    getContactList() {
        return authRequest.get('/sms/contacts/');
    }
}

export default new TwilioService();