import { authRequest,searchString } from './api/auth';

class CampaignDataService {
    list(page,search,pageitem,sortby) {
        search = searchString(search)
        return authRequest.get(`/campaign/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}`);
    }
    create(data) {
        return authRequest.post('/campaign/', data);
    }
    edit(data) {
        return authRequest.put(`/campaign/${data.id}/`, data);
    }
    delete(id) {
        return authRequest.delete(`/campaign/${id}/`)
    }
    bulkDelete(ids) {
        return authRequest.post('/campaign/bulkdelete/', ids)
    }
    detail(id) {
        return authRequest.get(`/campaign/${id}/`)
    }
    emaildetail(id) {
        return authRequest.get(`/campaign/email/${id}/`)
    }
    emailedit(data) {
        return authRequest.put(`/campaign/email/${data.id}/`, data);
    }
    campaignline(id,is_ai) {
        return authRequest.get(`/campaign/line/${id}/?is_ai=${is_ai}`)
    }
    campaignlinecontact(id,str) {
        return authRequest.get(`/campaign/${id}/analyze/${str}/`)
    }
    selectlist() {
        return authRequest.get('/campaign/selsectlist/');
    }
}
export default new CampaignDataService();