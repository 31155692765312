import { authRequest,searchString } from './api/auth';

class KnowledgeArticleDataService {
    create(data) {
        return authRequest.post('/knowledgearticle/', data);
    }
    list(page,search,pageitem,sortby) {
        search = searchString(search)
        return authRequest.get(`/knowledgearticle/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}`);
    }
    detail(id) {
        return authRequest.get(`/knowledgearticle/${id}/`);
    }
    edit(data) {
        return authRequest.put(`/knowledgearticle/${data.id}/`, data);
    }
    editauthor(data) {
        return authRequest.put(`/knowledgearticle/${data.id}/author/`, data);
    }
    delete(id) {
        return authRequest.delete(`/knowledgearticle/${id}/`);
    }
    publishedarticles(page, keyword) {
        if (keyword == undefined) {
            return authRequest.get(`/knowledgearticle/published/?page=${page}`);
        } else {
            keyword = searchString(keyword)
            return authRequest.get(`/knowledgearticle/published/?page=${page}&search=${keyword}`);
        }
    }
    
    //- Article Subject
    subjectlist() {
        return authRequest.get('knowledgearticle/subject/');
    }
    createsubject(data) {
        return authRequest.post('knowledgearticle/subject/', data);
    }
    editsubject(data) {
        return authRequest.put(`knowledgearticle/subject/${data.id}/`, data);
    }
    deletesubject(id) {
        return authRequest.delete(`knowledgearticle/subject/${id}/`);
    }
}
export default new KnowledgeArticleDataService();