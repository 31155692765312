import { authRequest,searchString } from './api/auth';

class OpportunityDataService {
    getOpptyDetail(id) {
        return authRequest.get(`/opportunity/${id}/`);
    }
    delOpptyDetail(id) {
        return authRequest.delete(`/opportunity/${id}/`);
    }
    editOpptyDetail(data){
        return authRequest.put(`/opportunity/${data.id}/` ,data);
    }
    getOpptytimeline(id) {
        return authRequest.get(`/opportunity/${id}/timeline/`);
    }
    getOpptyContacts(id) {
        return authRequest.get(`/opportunity/${id}/maincontact/${id}/`);
    }
    addOpptyContacts(id,data) {
        return authRequest.post(`/opportunity/${id}/maincontact/`,data);
    }
    delOpptyContacts(id,contactid) {
        return authRequest.delete(`/opportunity/${id}/maincontact/${contactid}/`);
    }
    getAddOpptyContactsList(id) {
        return authRequest.get(`/opportunity/${id}/maincontact/`);
    }
    opptySummaryUpdate(id, data) {
        return authRequest.put(`/opportunity/${id}/summary/`, data);
    }
    edit(data) {
        delete data.owner.usersetting.photo;
        return authRequest.put(`/opportunity/${data.id}/`, data);
    }
    quickCreate(data) {
        return authRequest.post(`/opportunity/`, data);
    }
    list(tab, stage_type_id, owner_id,page,search,pageitem,sortby,filter) {
        if(filter == undefined){
            filter = ""
        }
        search = searchString(search)
        return authRequest.get(`/opportunity/?tab=${tab}&stage_type=${stage_type_id}&owner=${owner_id}&page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&filter=${filter}`);
    }
    dashboardList(data, range, startDay, endDay) {
        return authRequest.get(`/dashboard/?owner=${data.people}&stage_type=${data.process}&day_range=${range}&start_date=${startDay}&end_date=${endDay}`);
    }
    dashboard30DayList(data, range, startDay, endDay,page, sort) {
        return authRequest.get(`/dashboard/?owner=${data.people}&stage_type=${data.process}&day_range=${range}&start_date=${startDay}&end_date=${endDay}&page=${page}&sortby=${sort}`);
    }
    updateCurrentStage(data) {
        return authRequest.put(`/opportunity/${data.id}/currentStage/`, data)
    }
    delete(id) {
        return authRequest.delete(`/opportunity/${id}/`)
    }
    getKanban(stage_type, owner) {
        return authRequest.get(`/opportunity/kanban/?stage_type=${stage_type}&owner=${owner}`)
    }
    getForecastview(stage_type, owner) {
        return authRequest.get(`/opportunity/forecastview/?stage_type=${stage_type}&owner=${owner}`)
    }
    editForecastview(data) {
        return authRequest.put(`/opportunity/${data.id}/forecastview/`, data)
    }
    kanban_stage(tab, stage_type_id, owner_id,page,search,pageitem,sortby,current_stage_id,roll_time,filter) {
        if(filter == undefined){
            filter = ""
        }
        search = searchString(search)
        return authRequest.get(`/opportunity/?tab=${tab}&stage_type=${stage_type_id}&owner=${owner_id}&page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&current_stage_id=${current_stage_id}&roll_time=${roll_time}&filter=${filter}`);
    }
    forecast_stage(tab, stage_type_id, owner_id,page,search,pageitem,sortby,current_date,roll_time,filter) {
        if(filter == undefined){
            filter = ""
        }
        search = searchString(search)
        return authRequest.get(`/opportunity/?tab=${tab}&stage_type=${stage_type_id}&owner=${owner_id}&page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}&current_date=${current_date}&roll_time=${roll_time}&filter=${filter}`);
    }
    getProductLineItem(id){
        return authRequest.get(`/opportunity/${id}/products`);
    }
    updateProductLineItem(id, data){
        return authRequest.post(`/opportunity/${id}/products/change`, data)
    }
    bulkdelete(id){
        let ids = id.split(",");
        let oppty_ids = {}
        oppty_ids.ids = ids
        return authRequest.post('/opportunity/bulk/delete/', oppty_ids);
    }
}
export default new OpportunityDataService();