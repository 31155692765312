import { authRequest } from './api/auth';

class CustomOpptyClosedReasonDataService {
    
    opptyclosedreasonlist() {
        return authRequest.get('oppty_closed_reason/');
    }
    createopptyclosedreason(data) {
        return authRequest.post('oppty_closed_reason/', data);
    }
    editopptyclosedreason(data) {
        return authRequest.put(`oppty_closed_reason/change/${data.id}/`, data);
    }
}
export default new CustomOpptyClosedReasonDataService();