import { authRequest } from './api/auth';

class SMSTemplateDataService {
    getAll() {
        return authRequest.get("/smstemplates/");
    }
    create(data) {
        return authRequest.post("/smstemplates/", data);
    }
    edit(data) {
        return authRequest.put(`/smstemplates/${data.id}/`, data);
    }
    delete(id) {
        return authRequest.delete(`/smstemplates/${id}/`);
    }
    // getAllForCompose(type, id) {
    //     return authRequest.get(`/emailtemplatescompose/?type=${type}&id=${id}`);
    // }



    // dashboardEmailList(data) {
    //     return authRequest.get(`/email/dashboard/?mode=${data.mode}&startDate=${data.startDate}&endDate=${data.endDate}`, data);
    // }
}

export default new SMSTemplateDataService();
