import { authRequest,searchString } from './api/auth';

class PostDataService {
    getSinglePost(search,filter_read,filter_mention,post_id) {
        search = searchString(search)
        return authRequest.get(`/post/?search=${search}&has_read=${filter_read}&has_mentioned=${filter_mention}&post_id=${post_id}`);
    }

    getAllPost(search,filter_read,filter_mention) {
        search = searchString(search)
        return authRequest.get(`/post/?search=${search}&has_read=${filter_read}&has_mentioned=${filter_mention}`);
    }

    createNewPost(data) {
        return authRequest.post(`/post/`,data);
    }    

    updatePost(data) {
        return authRequest.put(`/post/${data.id}`,data);
    }
    updatePostStatu(data) {
        return authRequest.put(`/post/regard/${data.id}/`, data);
    }  

    deletePost(id) {
        return authRequest.delete(`/post/${id}/`);
    }    

    passAttachment(id,attachment) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        let formData = new FormData();
        formData.append('attachment', attachment);
        formData.append('post', id);
        return authRequest.post(`/post/${id}/attachment/`,formData,config);
    }

    getAllAlert(search,filter_read,filter_mention) {
        search = searchString(search)
        return authRequest.get(`/post/alert?search=${search}&has_read=${filter_read}&has_mentioned=${filter_mention}`);
    }    

    updateAlertStatu(data) {
        return authRequest.put(`/post/alert/regard/${data.id}/`, data);
    } 
    
    deleteAlertregard(data) {
        return authRequest.delete(`/post/alert/regard/${data.id}?alert_id=${data.alert_id}&user_id=${data.user_id}`);
    } 

    updatePostEmoji(data) {
        return authRequest.post(`/post/emoji`, data);
    }
    deletePostEmoji(id) {
        return authRequest.delete(`/post/emoji/${id}/`);
    }
    deleteAllPost() {
        return authRequest.post(`/post/deleteall`);
    }
    deleteAllAlert() {
        return authRequest.post(`/post/alert/deleteall/`);
    }
    
}
export default new PostDataService();